import { createContext, FC, PropsWithChildren, Reducer, useContext, useReducer, useState } from 'react';
import { GlobalAction, GlobalContext as GlobalContextType, GlobalState } from 'interfaces/GlobalContext';

import { MyToverAPI } from 'api/MyToverAPI';
import { init, initialState, reducer, SET_LANGUAGES } from 'reducers/global-reducer';

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<GlobalState, GlobalAction>, typeof initialState>(
    reducer,
    initialState,
    init,
  );
  const [loading, setLoading] = useState(false);

  const getLanguages = async (refresh = false) => {
    if (loading || state.languages.length > 0) return;

    try {
      setLoading(true);
      const { success, parsed, errors } = await MyToverAPI.getLocales();
      if (success) {
        const { data: languages } = parsed;
        dispatch({ type: SET_LANGUAGES, languages });
      } else {
        console.log(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        loading,
        getLanguages,
        setLoading,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const globalContext = useContext(GlobalContext);

  if (!globalContext) throw Error('useGlobal: The hook is used outside its context.');

  return globalContext;
};
