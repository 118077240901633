import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { ApiResult } from 'interfaces/APITypes';
import { useSnackbar } from 'notistack';

import { contentInstance } from 'api/ContentAPI';
import { mytoverInstance } from 'api/MyToverAPI';
import { ToverzichtAPI, toverzichtInstance } from 'api/ToverzichtAPI';

export const useInterceptors = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const updateContentAPI = () => {
    contentInstance.interceptors.request.use((config) => {
      config.headers.set('X-Language', i18n.language);
      return config;
    });

    contentInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          const { data, status } = error.response;

          switch (status) {
            // Unprocessable Content
            case 422:
              enqueueSnackbar(t(data.errors.image[1]), { variant: 'error' });
              break;

            default:
              enqueueSnackbar(data.message, { variant: 'error' });
          }
          return Promise.reject(error);
        } else if (error.request) {
          // The request was made but no response was received
          enqueueSnackbar(t('error.request'), { variant: 'error' });
        }

        return Promise.reject(error.message);
      },
    );
  };

  const updateMyToverAPI = () => {
    mytoverInstance.interceptors.request.use((config) => {
      config.headers.set('X-Language', i18n.language);
      return config;
    });

    mytoverInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          const { data, status } = error.response;

          switch (status) {
            // Unauthorized
            case 401:
              break;
            // Forbidden
            case 403:
              navigate('/403', { replace: true });
              break;
            // Not found
            case 404:
              enqueueSnackbar(data.error ?? t('error.404.generic'), { variant: 'error' });
              navigate('/404');
              break;
            // Method Not Allowed
            case 405:
              enqueueSnackbar(data.error ?? t('error.405.generic'), { variant: 'error' });
              break;
            // Request Timeout
            case 408:
              enqueueSnackbar(t('error.408.generic'), { variant: 'warning' });
              break;
            // Missing/expired CSRF Token
            case 419:
              enqueueSnackbar(t('error.419.generic'), { variant: 'info' });
              navigate('/signin', { state: { from: location } });
              break;
            // Too many requests
            case 429:
              enqueueSnackbar(t('error.429.generic'), { variant: 'error' });
              break;
            default:
              enqueueSnackbar(t('error.unspecified'), { variant: 'error' });
          }

          return Promise.reject(error);
        } else if (error.request) {
          // The request was made but no response was received
          enqueueSnackbar(t('error.request'), { variant: 'error' });
        }

        return Promise.reject(error.message);
      },
    );
  };

  const updateToverzichtAPI = async () => {
    toverzichtInstance.interceptors.request.use((config) => {
      const token = sessionStorage.getItem('token');
      config.headers.set('Authorization', token ? `Bearer ${token}` : '');
      return config;
    });

    toverzichtInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if ((error.response.success === 401 || error.response.success === 403) && !originalRequest._retry) {
          originalRequest._retry = true;

          const token = await ToverzichtAPI.getToken().then((response) => response.parsed.access_token);
          toverzichtInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          return toverzichtInstance(originalRequest);
        }

        return Promise.reject(error);
      },
    );
  };

  useEffect(() => {
    updateContentAPI();
    updateMyToverAPI();
    updateToverzichtAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const buildAxiosResult = async (response: AxiosResponse<any>): Promise<ApiResult> => {
  const status = response.status;
  const parsed = status === 204 ? 'None' : await response.data;

  const result: ApiResult = {
    success: status >= 200 && status < 300,
    parsed,
    statusCode: status,
  };

  if (!result.success || parsed.errors) {
    result.errors = parsed.errors ?? response.data.error;
  }

  return result;
};
