import axios from 'axios';
import { ApiResult } from 'interfaces/APITypes';

import { buildAxiosResult } from 'utils/axios';

export const toverzichtInstance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
});

export class ToverzichtAPI {
  static async getToken(): Promise<ApiResult> {
    const data = {
      grant_type: 'client_credentials',
      client_id: process.env.REACT_APP_LEGACY_CLIENT_ID,
      client_secret: process.env.REACT_APP_LEGACY_CLIENT_SECRET,
      scope: 'read-config',
    };

    const response = await toverzichtInstance.post('/oauth/token', data);
    if (response.data.access_token) sessionStorage.setItem('token', response.data.access_token);
    toverzichtInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
    return await buildAxiosResult(response);
  }

  static async getConfigurationUpdate(serial: string): Promise<ApiResult> {
    if (!sessionStorage.getItem('token')) {
      await this.getToken();
    }
    const response = await toverzichtInstance.get(`/v2/api/unit/${serial}/configUrl`);
    return await buildAxiosResult(response);
  }
}
