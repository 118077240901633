import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FeedbackAnswer, FeedbackQuestion } from 'interfaces/FeedbackTypes';
import {
  Box,
  Button,
  IconContainerProps,
  Rating,
  Slider,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { MyToverAPI } from 'api/MyToverAPI';
import { CustomEventDetail } from 'contexts/EventContext';
import { FeedbackContext } from 'contexts/FeedbackContext';
import { FeedbackEventName, isValidSection, SET_SESSION_STORAGE } from 'reducers/feedback-reducer';
import NonmodalDialog from 'components/shared/NonmodalDialog';

const FeedbackNonmodal = () => {
  const { t } = useTranslation();

  const feedback = useContext(FeedbackContext);
  const location = useLocation();
  const [eventDetail, setEventDetail] = useState<CustomEventDetail | undefined>();
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState<FeedbackQuestion | undefined>();
  const [questions, setQuestions] = useState<FeedbackQuestion[]>([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const onMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{value}</span>;
  };

  const handleTrigger = useCallback(
    async ({ detail }: CustomEvent<CustomEventDetail>) => {
      setEventDetail(detail);

      if (!isValidSection(detail.name)) return;
      if (!feedback?.state[detail.name]) return;

      try {
        const { success, parsed } = await MyToverAPI.getFeedbackQuestions(detail.name);
        if (success && parsed.data.length > 0) {
          setQuestions(parsed.data);
          feedback?.setShow(true);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [feedback],
  );

  const noFurtherQuestions = questionIndex + 1 === questions.length;

  const resetFields = () => {
    setRating(0);
    setComment('');
  };

  const handleClose = (event?: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;

    feedback?.dispatch({ type: SET_SESSION_STORAGE, name: eventDetail!.name as FeedbackEventName });
    feedback?.setShow(false);
  };

  const handleNext = () => setCurrentQuestion(questions[questionIndex + 1]);

  const getNextQuestion = () => {
    if (noFurtherQuestions) {
      handleClose();
    } else {
      handleNext();
    }
  };

  const handleAnswer = async () => {
    if (!currentQuestion) return;

    const payload: FeedbackAnswer = {
      referer: location.pathname,
      event: eventDetail?.name,
      question_id: currentQuestion.id,
      rating: currentQuestion.type === 'rating' ? rating : undefined,
      message: currentQuestion.type === 'comment' ? comment : undefined,
    };

    try {
      // on error, global alert still shows, despite loading next question
      // TODO: return specific error message; error.500.feedback?
      await MyToverAPI.postFeedback(payload);
    } finally {
      getNextQuestion();
      resetFields();
    }
  };

  useEffect(() => {
    window.addEventListener('prompt-feedback', handleTrigger);

    return () => {
      window.removeEventListener('prompt-feedback', handleTrigger);
    };
  }, [handleTrigger]);

  useEffect(() => {
    if (eventDetail) feedback?.dispatch({ type: SET_SESSION_STORAGE, name: eventDetail!.name as FeedbackEventName });
    feedback?.setShow(false);
    return resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (questions.length > 0) setCurrentQuestion(questions[0]);

    return () => setCurrentQuestion(undefined);
  }, [questions]);

  useEffect(() => {
    setQuestionIndex(questions.findIndex((question) => question.id === currentQuestion?.id));
  }, [questions, currentQuestion]);

  return (
    <NonmodalDialog
      title={`Question ${questionIndex + 1} of ${questions.length}`}
      open={feedback?.show}
      onClose={handleClose}
      actions={
        <Button fullWidth color="secondary" onClick={() => handleAnswer()} sx={{ my: 1 }}>
          {noFurtherQuestions ? t('feedback.form.finish') : t('feedback.form.next')}
        </Button>
      }
    >
      {currentQuestion && (
        <>
          <Typography variant="body2" gutterBottom>
            {t(currentQuestion.question)}
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            {currentQuestion.type === 'rating' && (
              <Box>
                {onMobile ? (
                  <Slider
                    aria-label="feedback-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={currentQuestion.options.rating_min ?? 1}
                    max={currentQuestion.options.rating_max ?? 10}
                    value={rating}
                    onChange={(_, newValue) => setRating(newValue as number)}
                  />
                ) : (
                  <Rating
                    highlightSelectedOnly
                    IconContainerComponent={IconContainer}
                    max={currentQuestion.options.rating_max ?? 10}
                    name="feedback-rating"
                    sx={{
                      'color': 'secondary.main',
                      '& .MuiRating-icon': {
                        'bgcolor': 'grey.200',
                        'borderRadius': 1,
                        'color': 'secondary.main',
                        'fontSize': (theme) => theme.typography.fontSize,
                        'px': 3,
                        'py': 1,
                        'mr': 1,
                        '&.MuiRating-iconFilled': {
                          bgcolor: 'primary.main',
                        },
                      },
                    }}
                    value={rating}
                    onChange={(_, newValue) => setRating(newValue as number)}
                  />
                )}
                {currentQuestion.labels && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography color="text.disabled">{t(currentQuestion.labels.low)}</Typography>
                    <Typography color="text.disabled">{t(currentQuestion.labels.high)}</Typography>
                  </Box>
                )}
              </Box>
            )}

            {currentQuestion.type === 'comment' && (
              <TextField
                multiline
                minRows={2}
                maxRows={4}
                value={comment}
                onChange={(event) => setComment(event.target.value)}
              />
            )}
          </Box>
        </>
      )}
    </NonmodalDialog>
  );
};

export default FeedbackNonmodal;
