import { AxiosResponse } from 'axios';
import { ApiResult, Options } from 'interfaces/APITypes';

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

export const buildResult = async (response: Response): Promise<ApiResult> => {
  const { status } = response;
  const parsed = status === 204 ? 'None' : await response.json();

  const result: ApiResult = {
    success: status >= 200 && status < 300,
    parsed,
    statusCode: status,
  };

  if (!result.success) result.errors = parsed.errors;

  return result;
};

export const buildAxiosResult = async (response: AxiosResponse<any>): Promise<ApiResult> => {
  const status = response.status;
  const parsed = status === 204 ? 'None' : await response.data;

  const result: ApiResult = {
    success: status >= 200 && status < 300,
    parsed,
    statusCode: status,
  };

  if (!result.success || parsed.errors) {
    console.log('errors in response');
    result.errors = parsed.errors ?? response.data.error;
  }

  return result;
};
