import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Box, Fade } from '@mui/material';

import { useAuth } from 'contexts/AuthContext';
import { useInterceptors } from 'hooks/useInterceptors';
import { useScrollTopOnNavigation } from 'hooks/useScrollTopOnNavigation';
import { GuestModeBanner } from 'components/account/GuestModeBanner';
import { Footer } from 'components/app/Footer';
import { Header } from 'components/app/Header';
import { PageLoader } from 'components/app/PageLoader';
import FeedbackNonmodal from 'components/feedback/FeedbackNonmodal';
import UserFeedback from 'components/feedback/UserFeedback';
import { EULADialog } from 'components/mytover/EULADialog';

import { MyToverAPI } from '../../../api/MyToverAPI';

export const Layout: FC = () => {
  useScrollTopOnNavigation();
  useInterceptors();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const [open, setOpen] = useState(false);
  const openEULADialog = () => setOpen(true);
  const closeEULADialog = () => setOpen(false);

  const clearQrNavigationParameters = (query: URLSearchParams) => {
    query.delete('token');
    query.delete('referrer');
    query.delete('referer');
    const searchParams = query.toString();

    // Replace current URL without QR-referenced search parameters
    navigate(location.pathname + (searchParams ? `?${searchParams}` : ''), { replace: true });
  };
  /**
   * If the search parameters are met, force the login as guest; if the user is
   * logged in by the time arriving to the site, the account gets logged out and
   * force login as a guest. It is still possible to just login as a normal user
   * from that point on
   */
  const forceLoginOrder = async () => {
    const query = new URLSearchParams(location.search);
    const isQRRefer = (query.get('referrer') || query.get('referer')) === 'qr';
    const token = query.get('token');

    if (auth.determined && auth.state.account && isQRRefer && token) {
      try {
        const { success } = await MyToverAPI.checkQrAccess(token);
        if (success) {
          clearQrNavigationParameters(query);
          return;
        }
      } catch (error) {
        // no access: sign out, reload and try as guest.
        await auth.signout();
        window.location.replace(location.pathname + location.search);
      }
    }

    if (auth.determined && !auth.state.account && isQRRefer && token) {
      await auth.signInAsGuest({ token });

      // Remove QR-referenced search parameters from URL
      clearQrNavigationParameters(query);
    } else if (!auth.determined && !auth.state.account) {
      // If user is not yet retrieved from backend, attempt
      auth.getAccount().finally(() => auth.setLoading(false));
    }
  };

  useEffect(() => {
    // Issues can arise during development mode due to React's Strict Mode policy,
    // but it can be ignored as it won't happen in production mode.
    forceLoginOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.determined]);

  useEffect(() => {
    if (location.state?.error) {
      enqueueSnackbar(t(`error.${location.state.error.success}.generic`), {
        variant: location.state.error.variant,
        preventDuplicate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (auth.state.account?.eula?.new_version_available) {
      openEULADialog();
    }

    return () => closeEULADialog();
  }, [auth.state.account]);

  return (
    <>
      <Fade in={auth.determined}>
        <Box component="main" className="site">
          <Header />
          <Box className="site__content">
            {auth.hasRole('guest') && <GuestModeBanner />}
            <Outlet />
          </Box>
          <Footer />
          <UserFeedback />
          <FeedbackNonmodal />
          <EULADialog open={open} close={closeEULADialog} />
        </Box>
      </Fade>
      <PageLoader determined={auth.determined} />
    </>
  );
};
